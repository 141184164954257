"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interSemiBold = void 0;
exports.interSemiBold = {
    familyName: "Inter",
    weight: "600",
    style: "normal",
    ttfName: "Inter-SemiBold",
    ttfFile: "inter-semi-bold.ttf",
    woff2File: "inter-semi-bold.woff2",
};
