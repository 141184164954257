"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crimsonPro = void 0;
const crimson_pro_medium_1 = require("../fonts/crimson-pro-medium");
exports.crimsonPro = {
    headingXLarge: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 36,
        lineHeight: 38,
        letterSpacing: -0.72,
        textTransform: undefined,
    },
    headingLarge: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 28,
        lineHeight: 32,
        letterSpacing: -0.56,
        textTransform: undefined,
    },
    headingMedium: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 22,
        lineHeight: 28,
        letterSpacing: -0.44,
        textTransform: undefined,
    },
    headingSmall: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: 1.8,
        textTransform: "uppercase",
    },
    body: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: crimson_pro_medium_1.crimsonProMedium,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
