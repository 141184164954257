"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.crimsonProMedium = void 0;
exports.crimsonProMedium = {
    familyName: "Crimson Pro",
    weight: "500",
    style: "normal",
    ttfName: "CrimsonProExtraLight-Medium",
    ttfFile: "crimson-pro-medium.ttf",
    woff2File: "crimson-pro-medium.woff2",
};
