"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inter = void 0;
const inter_semi_bold_1 = require("../fonts/inter-semi-bold");
const inter_regular_1 = require("../fonts/inter-regular");
exports.inter = {
    headingXLarge: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 28,
        lineHeight: 38,
        letterSpacing: -0.56,
        textTransform: undefined,
    },
    headingLarge: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 22,
        lineHeight: 32,
        letterSpacing: -0.44,
        textTransform: undefined,
    },
    headingMedium: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 18,
        lineHeight: 28,
        letterSpacing: -0.36,
        textTransform: undefined,
    },
    headingSmall: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 14,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 12,
        lineHeight: 20,
        letterSpacing: 1.8,
        textTransform: "uppercase",
    },
    body: {
        font: inter_regular_1.interRegular,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: inter_regular_1.interRegular,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: inter_semi_bold_1.interSemiBold,
        fontSize: 14,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
