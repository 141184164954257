import { t } from "i18next"

import { DisplayableError } from "@treefort/lib/displayable-error"

import { refetchUserSubscriptions } from "../../../hooks/subscriptions"
import { getSubscriptionPlan } from "../../subscription-plans"
import { getUserSubscriptions } from "../../subscriptions"
import { checkoutSessionManager } from "../session"

/**
 * Runs some last minute checks to make sure that completing the payment won't
 * put the user in an invalid subscripion state. Should be called inside the
 * requestPayment implimentation for each platform
 */
export async function ensureRequestPaymentAllowed(
  provider: "stripe" | "appStore" | "playStore" | "webPayment",
  oldPurchaseTokenAndroid?: string,
) {
  const { subscriptions, currentSubscriptionId, redundantSubscriptionIds } =
    await getUserSubscriptions()

  // If the user is in a bad state then stop now.
  if (redundantSubscriptionIds.length) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("You are already subscribed."),
      new Error(
        `[Checkout] User attempted payment while subscribed to multiple plans`,
      ),
    )
  }

  // Extract the user's current subscription and the plan that goes with it
  const subscription = subscriptions.find(
    (sub) => sub.id === currentSubscriptionId,
  )
  const currentPlan = subscription
    ? await getSubscriptionPlan(subscription.subscriptionPlanId)
    : undefined

  // If the user has an uncanceled paid plan from another provider then we ask
  // them to manually cancel it first. On a technical level we might be able to
  // cancel the plan automatically, but we can't prorate switches between
  // providers. The idea is that if we force the user to manually cancel the old
  // plan we will reduce the chance that they excpect the new plan to be
  // prorated when they sign up again. This is pretty subjective - implement
  // automatic cancellation and tear down this fence if you have good reason to.
  if (
    subscription &&
    currentPlan &&
    currentPlan.provider !== provider &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt &&
    // Switching away from a group membership doesn't involve proration so we
    // allow the user to do this seamlessly.
    currentPlan.provider !== "groupMembership"
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t(
        "You are already subscribed. Please cancel your current subscription first.",
      ),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted payment on one platform, but they have an uncanceled subscription on another platform`,
      ),
    )
  }

  // We're switching plans on Android but we're missing the previous
  // subscription's purchase token.
  if (
    subscription &&
    provider === "playStore" &&
    currentPlan?.provider === "playStore" &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt &&
    !oldPurchaseTokenAndroid
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("Something went wrong, please try again."),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted payment on Android but the old purchase token wasn't provided when necessary`,
      ),
    )
  }

  // We can't use the request payment flow to switch Stripe plans (that must be
  // done via the Stripe billing portal or our built-in plan switching logic).
  if (
    subscription &&
    provider === "stripe" &&
    currentPlan?.provider === "stripe" &&
    !subscription.canceledAt &&
    !subscription.deactivatedAt
  ) {
    checkoutSessionManager.endSession({ complete: false })
    await refetchUserSubscriptions()
    throw new DisplayableError(
      t("You are already subscribed."),
      new Error(
        `[Checkout] User with subscription id ${subscription.id} attempted to open the Stripe checkout portal but they already have an uncanceled Stripe subscription`,
      ),
    )
  }
}
