"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.interRegular = void 0;
exports.interRegular = {
    familyName: "Inter",
    weight: "400",
    style: "normal",
    ttfName: "Inter-Regular",
    ttfFile: "inter-regular.ttf",
    woff2File: "inter-regular.woff2",
};
