import React from "react"
import { useTranslation } from "react-i18next"

import { useDisplayModeSetting } from "../hooks/use-display-mode-setting"
import { Button } from "./button"
import SettingsFieldset from "./settings-fieldset"
import SettingsRow from "./settings-row"
import Spacer from "./spacer"

const flex = { flex: 1 }

export default function SettingsDisplayMode(): JSX.Element {
  const [displayMode, setDisplayMode] = useDisplayModeSetting()
  const { t } = useTranslation()

  return (
    <SettingsFieldset title={t("Display mode")}>
      <SettingsRow>
        <Button
          type={displayMode === "light" ? "primary" : "secondary"}
          onPress={() => setDisplayMode("light")}
          style={flex}
          containerStyle={flex}
        >
          {t("Light")}
        </Button>
        <Spacer size="small" horizontal />
        <Button
          type={displayMode === "dark" ? "primary" : "secondary"}
          onPress={() => setDisplayMode("dark")}
          style={flex}
          containerStyle={flex}
        >
          {t("Dark")}
        </Button>
        <Spacer size="small" horizontal />
        <Button
          type={displayMode === "auto" ? "primary" : "secondary"}
          onPress={() => setDisplayMode("auto")}
          style={flex}
          containerStyle={flex}
        >
          {t("Auto")}
        </Button>
      </SettingsRow>
    </SettingsFieldset>
  )
}
